import React, { useState } from 'react';
import getCurrentMoonPhase from '../Password/getCurrentMoonPhase'; // Import the getCurrentMoonPhase function
import WordleAnswers from "../Password/WordleAnswers";
import sponsor from "../../../img/sponsor.png";


export default function Pswd(){
    const [password, setPassword] = useState('');
    const [stage, setStage] = useState(0);

    const handleInputChange = (e) => {
        setPassword(e.target.value);
    };

    const handleNextStage = () => {
        window.location.href = "/mors"
    };

    const renderStage = () => {
        switch (stage) {
            case 0:
                const validationResults = validatePassword();
                return (
                    <div className="password-game-stage">
                        <input
                            type="input"
                            value={password}
                            onChange={handleInputChange}
                            placeholder="Wpisz swoje haslo"
                        />
                        <button
                            onClick={handleNextStage}
                            disabled={
                                !validationResults.isLengthValid ||
                                !validationResults.hasUpperCase ||
                                !validationResults.hasSpecialCharacter ||
                                !validationResults.hasNumber ||
                                validationResults.digitSum !== 25 ||
                                !validationResults.hasLeapYear ||
                                !validationResults.hasMonth ||
                                !validationResults.hasRomanNumeral ||
                                !validationResults.hasSponsors ||
                                validationResults.romanNumeralsProduct !== 35 ||
                                !validationResults.hasTwoLetterSymbol ||
                                !validationResults.hasCurrentMoonPhase ||
                                !validationResults.hasWordle
                            }
                        >
                            Potwierdz
                        </button>
                        <div className="password-hints">
                            <p>{validationResults.isLengthValid ? '✓' : '❌'} Haslo musi miec przynjamniej 8 znakow</p>
                            <p>{validationResults.hasUpperCase ? '✓' : '❌'} Haslo musi miec przynjamniej jedna duza litera</p>
                            <p>{validationResults.hasSpecialCharacter ? '✓' : '❌'} Haslo musi miec przynjamniej jeden znak spejalny</p>
                            <p>{validationResults.hasNumber ? '✓' : '❌'} Haslo musi miec przynjamniej jedna liczbe</p>
                            <p>{validationResults.digitSum === 25 ? '✓' : '❌'} Liczby w hasle musza dodawac sie do 25</p>
                            <p>{validationResults.hasLeapYear ? '✓' : '❌'} Haslo musi miec rok przestepny jako pierwsza liczbe w hasle</p>
                            <p> {validationResults.hasMonth ? '✓' : '❌'} Twoje haslo musi posiadac nazwe miesiaca</p>
                            <p> {validationResults.hasRomanNumeral ? '✓' : '❌'} Haslo musi posiadac liczbe rzymska</p>
                            <p> {validationResults.hasSponsors ? '✓' : '❌'} Haslo musi posiadac nazwe naszego sponsora</p>
                            <img
                                src={sponsor}
                                alt="React Logo"
                                className="book-img" />
                            <p> {validationResults.romanNumeralsProduct === 35 ? '✓' : '❌'} Liczby rzymskie musza sie mnozyc do 35</p>
                            <p>{validationResults.hasTwoLetterSymbol ? '✓' : '❌'} Haslo musi posiadac dwuliterowy znak z tablicy elementow</p>
                            <p>{validationResults.hasCurrentMoonPhase ? '✓' : '❌'} Haslo musi posiadac aktualna faze ksiezyca jako emotke</p>
                            <p>{validationResults.hasWordle ? '✓' : '❌'} Haslo musi posiadac dzisiejsza odpowiedz z wordle</p>
                        </div>
                    </div>
                );
            // ... render other stages
            default:
                return null;
        }
    };

    const validatePassword = () => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasNumber = /\d/.test(password);

        const isLengthValid = password.length >= 8;

        let digitSum = 0;
        if (hasNumber) {
            const digits = password.match(/\d/g);
            digitSum = digits.reduce((sum, digit) => sum + parseInt(digit, 10), 0);
        }

        const hasMonth = /(Styczeń|Luty|Marzec|Kwiecień|Maj|Czerwiec|Lipiec|Sierpień|Wrzesień|Październik|Listopad|Grudzień)/i.test(password);

        const hasRomanNumeral = /(I|II|III|IV|V|VI|VII|VIII|IX|X|L|XC|C|CD|D|CM|M)/.test(password);

        const hasSponsors = /(Kfc|Mcdonald|Pepsi)/i.test(password);

        //mnozenie liczb rzymskich
        const romanNumeralsProduct = calculateRomanNumeralsProduct(password);

        //two letter periodic table
        //const hasTwoLetterSymbol = /\b[A-Z][a-z]{1}\b/.test(password);
        const hasTwoLetterSymbol = periodicTableSymbols.some(symbol => password.includes(symbol));

        //leap year
        const hasLeapYear = /\b((0*([1-9][0-9]{0,3})|([1-4][0-9]{3,3}))|(5000))\b/.test(password);

        // Extract the potential year from the password
        const extractedYearMatch = password.match(/\d+/g);

        const extractedYear = extractedYearMatch ? parseInt(extractedYearMatch[0], 10) : null;

        const isLeapYear = extractedYear !== null && (extractedYear % 4 === 0 && (extractedYear % 100 !== 0 || extractedYear % 400 === 0));


        console.log(extractedYearMatch);
        console.log(extractedYear);

        //moon
        const currentDate = new Date(); // You can replace this with any desired date
        const moonEmoji = getCurrentMoonPhase();
        //console.log(`Moon Phase on ${currentDate.toDateString()}: ${moonEmoji}` + " aaaaaaaaaaaaaaaaaa");

        const hasCurrentMoonPhase = password.includes(getCurrentMoonPhase());

        //WORDLE
        const wordle = WordleAnswers();
        const pattern = new RegExp(wordle, "i");
        //console.log(wordle + " wordle na dzis")
        const hasWordle = pattern.test(password);


        return {
            hasUpperCase,
            hasSpecialCharacter,
            hasNumber,
            isLengthValid,
            digitSum,
            hasMonth,
            hasRomanNumeral,
            hasSponsors,
            romanNumeralsProduct,
            hasTwoLetterSymbol,
            hasLeapYear: isLeapYear, // Check if both conditions are met
            hasCurrentMoonPhase,
            hasWordle,
        };
    };

    const periodicTableSymbols = [
        "He", "Be", "Ne", "Na", "Mg", "Al", "Si", "Cl", "Ar",
        "Ca", "Sc", "Ti", "V", "Cr", "Mn", "Fe", "Ni", "Cu", "Zn",
        "Ga", "Ge", "As", "Se", "Br", "Kr", "Rb", "Sr", "Zr",
        "Nb", "Mo", "Tc", "Ru", "Rh", "Pd", "Ag", "Cd", "In", "Sn",
        "Sb", "Te", "Xe", "Cs", "Ba", "La", "Ce", "Pr", "Nd",
        "Pm", "Sm", "Eu", "Gd", "Tb", "Dy", "Ho", "Er", "Tm", "Yb",
        "Lu", "Hf", "Ta", "Re", "Os", "Ir", "Pt", "Au", "Hg", "Tl",
        "Pb", "Bi", "Po", "At", "Rn", "Fr", "Ra", "Ac", "Th", "Pa",
        "Np", "Pu", "Am", "Cm", "Bk", "Cf", "Es", "Fm", "Md", "No", "Lr"
    ];


    const calculateRomanNumeralsProduct = (input) => {
        const romanNumeralValues = {
            I: 1,
            IV: 4,
            V: 5,
            IX: 9,
            X: 10,
            XL: 40,
            L: 50,
            XC: 90,
            C: 100,
            CD: 400,
            D: 500,
            CM: 900,
            M: 1000
        };
        const numerals = input.match(/(IV|IX|XL|XC|CD|CM)|[IVXLCDM]+/g);

        if (!numerals) {
            return null;
        }

        const values = numerals.map(numeral => {
            const isCombinedNumeral = numeral.length > 1;
            if (isCombinedNumeral) {
                let totalValue = 0;
                let currentIndex = 0;

                while (currentIndex < numeral.length) {
                    const currentSymbol = numeral[currentIndex];
                    const nextSymbol = numeral[currentIndex + 1];
                    const combinedSymbol = currentSymbol + nextSymbol;

                    if (romanNumeralValues[combinedSymbol]) {
                        totalValue += romanNumeralValues[combinedSymbol];
                        currentIndex += 2;
                    } else {
                        totalValue += romanNumeralValues[currentSymbol];
                        currentIndex += 1;
                    }
                }

                return totalValue;
            } else {
                return romanNumeralValues[numeral] || 1;
            }
        });

        const product = values.reduce((acc, value) => acc * value, 1);

        return product;
    };



    return (
        <div className="paragrafowa--content">
            <header className="paragrafowa--content">
                <h1>Wpisz haslo jesli potrafisz</h1>
            </header>
            <p>Kawezedzi maja wielka obsesje na punkcie bezpieczenstwa dlatego chca miec pewnosc ze osoby ktore odwiedzaja ich planete potrafia to uszanowac jak i rowniez sami potrafia tworzyc zlozone hasla.</p>
            <p>Licza rowniez na spostrzgawczosc jak i kreatywne myslenie nei zawsze beda Wam mowic jak tutaj co nalezy zrobic, czasem musicie wykazac sie sami!</p>
            <main className="App-main">
                {renderStage()}
            </main>
        </div>
    );
}
