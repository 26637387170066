import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from 'react';

export default function Robotnicy(){

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Po krotkiej acz goraczkowo przeprowadzonej rozmowie dowiadujesz sie ze od miesiecy ignorowales ich prosby o naprawniu klimatyzacji... Taka blahostka a przerodzila sie w poczatek konca. Co zamierzasz z tym zrobic?</p>
             <div className="cont">
                 <button className="timer--button">
                     <Link to="/dylemat" className="button--link--parag">
                         Coz za wstyd. Czym predzej kaze naprawic klimatyzacje i przepraszam oczywiscie za cala zaistniala sytuacje.
                         Niestety wiem ze koszt naprawy bedzie trzeba pokryc z jakiejs innej inwestycji…
                     </Link>
                     <span className="first"></span>
                     <span className="second"></span>
                     <span className="third"></span>
                     <span className="fourth"></span>
                 </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/represje" className="button--link--parag">
                        Nie przyznajesz sie ze wczesniej wiedziales o jakiejkolwiek usterce. Poza tym klimatyzacja?
                        Co to za durny powod zeby wszczynac bunt! Nie twierdzisz ze moglo byc im cieplej niz zwykle ale to zaden powod.
                        Przekazujesz robotnikom informacje ze klimatyzacja BEDZIE naprawiona ale kazdy kto byl zaangazowany w bunt musi
                        zostac przywolany do porzadku i przypomniec sobie w jakim celu uczestniczy w tej podrozy.
                        Dlatego tez kazdy kto bral udzial w tej farsie zostanie wykluczony z zycia kulturalnego statku na jakis czas.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}