import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Niemoc() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes Jarlboyem Knary kosmicznej. Misja TERA CON PRIME trwa juz 9 lat, czyli dokladnie polowe. Moze nie wszystko idzie zgodnie z planem, bo co i rusz ktos cie denerwuje i musisz go wykluczac z zycia publicznego, ale cieszysz sie swoja wladza i jestes dumny, ze z kazdym problemem radzisz sobie tak sprawnie. Ludzie musza czuc respekt do Jarlboya. Idac glownym korytarzem statku, nie rozgladasz sie na boki, bo zobaczylbys tam plakaty ze swoja karykatura. Na szczescie udalo ci sie juz ukarac ich autorow. Zaopatrzeniowcy, ktorzy przynosza Ci skladniki odzywcze, musza robic to w maskach i rekawiczkach. Jeden zbyt gleboki oddech i laduja w zamknietym pokladzie. W swojej kabinie masz prawdziwy spokoj: kiedy pragniesz odwiedza Cie robot do bicia, a poza tym mozesz rozkoszowac sie bloga samotnoscia. Dzis rano obudziles sie z zawrotami glowy, masz dusznosci i goraczke. Odruchowo chcesz wezwac zielarza, ale wszyscy siedza na podkladzie zamknietym, gdzie umiesciles ich za nieposluszenstwo. Mozesz ich uwolnic i wtedy Ci pomoga, ale stawiaja warunek: musisz podac sie do dymisji i przeprosic. Co robisz? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/upadek" className="button--link--parag">
                        Nigdy w zyciu! Nie beda mnie szantazowac, lajdaki w skorach jelenia! Sam sie wykuruje!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/przewodniczaca" className="button--link--parag">
                        Chyba przesadzilem z cala ta sytuacja na statku! Zycie jest najwazniejsze, pal licho wladze!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}