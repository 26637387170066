import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Nienawisc() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>W tym momencie konczy sie misja Tera Con Prime. Nie dotrzecie na planete i nie poznacie Kawezedow. Stalo sie tak z powodu Waszej biernosci i braku reakcji na przemoc. Kiedy Jarlboy dyskryminowal slabszych, odbieral im prawa, a z nielicznych sprawiedliwych i odwaznych osob robil kozly ofiarne, przechodziliscie wobec tego obojetnie. Odwracanie przez Was wzroku od krzywdy innych doprowadzilo do calkowitego poddanstwa i stworzenia systemu sluzacego tylko jednej osobie. Nie zasluzyliscie na spotkanie z nami. Szkoda.</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/wyprawa" className="button--link--parag">
                        Niestety misja zakonczyla sie niepowidzeniem, sprobuj jescze raz!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}