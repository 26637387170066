import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Salon() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes szefowa galerii sztuki na pokladzie Knary Kosmicznej. Poniewaz Jarlboy wlasnie odcial czesc budzetu przeznaczona na kulture, Twoje miejsce stracilo racje bytu. Jeden z Twoich stalych gosci proponuje, by w galerii utworzyc teraz podziemny klub walk. Wiesz, ze podczas misji TERA CON PRIME obowiazuja dosc rygorystyczne zasady zakazujace przemocy w jakiejkolwiek formie. Zabronione sa rowniez wojenne gry komputerowe. W Waszym klubie walk walczylibyscie jednak w kaskach VR, wiec nikt nie mialby fizycznych obrazen, cala gra odbywalaby sie w rzeczywistosci wirtualnej. Co Ty na to? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/roboty" className="button--link--parag">
                        Swietny pomysl! Ludzie potrzebuja sie wyzyc w bezpieczny sposob, zwlaszcza gdy Jarlboy nie panuje nad soba i wymysla glupie przepisy
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/mendex" className="button--link--parag">
                        To jest miejsce kultury w ktorej nie ma miejsca na VR czy gry komputerowe tylko na sztuke!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}