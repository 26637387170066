import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Rada() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes czlonkiem Rady Statku Kosmicznego.
                W twoje rece trafil wlasnie raport dotyczacy agresji i
                lamaniu praw przez Kapitan. To nie pierwszy raz gdy dostajesz
                skargi na Kapitan (przypominasz sobie ze donosili juz robotnicy,
                lekarze, a nawet dowodca slozb porzadkowych). Co z tym zrobisz?
            </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/okularnicy" className="button--link--parag">
                        Nie zaprzeczasz ze to wazny temat ale masz na glowie wazniejsze tematy, na przyklad niewystarczajaca ilosc gwiezdnego pylu do najblizszej kwartalnej celebracji, zgloszona awaria na dziobie knary. Sprawa Jarlboya moze poczekac do momentu gdy ogarniemy tamte tematy.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/psycholog" className="button--link--parag">
                        Jarlboy swoimi przemocowymi zachowaniami godzi w podstawy naszej misji. Podejmujesz natychmiastowe kroki ostrzegawcze.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}