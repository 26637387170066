import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Krysys() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes zielarka. Do tej pory to byla najspokojniesza praca Twojego zycia. Twoim zadaniem do tej pory bylo ukladanie diet czy odprawianie rytualow raz na kwartal. Niestety od kilku dni trafiaja do Twojego ambulatorium pobici pracownicy zaopatrzenia. Prawie wszystkie lozka zostaly juz zajete, niektorzy  pracownicy sa mocno poturbowani, maja polamane nogi i zebra. Jak tak dalej pojdzie, skoncza sie Wam srodki medyczne i czeka Was kryzys. Co czujesz, myslisz i robisz w tej sytuacji?  </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/okularnicy" className="button--link--parag">
                        Czuje strach, nie chce podpasc agresji Jarlboya. Bede wykonywac swoja prace jak do tej pory i nie zwracac na siebie uwagi.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/rada" className="button--link--parag">
                        Tak nie moze byc pisze do Rady Statku raport na temat lamania praw czlowieka oraz agresji Jarlboya niezgodnych z pokojowa misja. Nie moge siedziec cicho, gdy innym dzieje sie krzywda!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}