import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Pycha() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Niestety nigdzie nie dolecicie. Zgubila Was pycha, zadza wladzy i chec zemsty. Nie tak powinno to wygladac. Jarlboy narobil wiele zla, dyskryminujac i karzac niektore grupy spoleczne, przy biernej postawie pasazerow i obojetnosci na krzywde slabszych. Czesc osob pokladalo wielkie nadzieje w Przewodniczacej jako liderce, ktora poprowadzi statek i misje do celu. Jednak kiedy gore nad dobrem ogolu znow wziela wlasna ambicja i polityczny egoizm, zniknely szanse na dotarcie do planety Tera Con Prime. To przykre. </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/wyprawa" className="button--link--parag">
                        Niestety misja zakonczyla sie niepowidzeniem, sprobuj jescze raz!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}