import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Atak() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes jednym z pasazerow misji TERA CON PRIME. Przed chwila do Waszej knary zblizyl sie pojazd rodziny mieszkancow Mendexu.
                To najbardziej ugodowe, zyjace od tysiecy lat w pokoju i wzajemnym szacunku istoty w przestrzeni kosmicznej. Moglibyscie wiele sie od nich nauczyc,
                by pomoc kawezedom w potrzebie, oraz rozwinac swoje skrzydla niczym sep na prerii krazacy nad padlina. Niestety wymierzyliscie pocisk prosto w ich
                latajacy pojazd przypominajacy mechanicznego delfina. Nie nauczycie sie lagodnosci ani szacunku do innych od kosmitow, musicie nad tym popracowac sami.
                Statek Mendexian odlatuje mocno uszkodzony. Na ten moment przyszlosc misji jest niewyrazna.</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/wyprawa" className="button--link--parag">
                        Niestety misja zakonczyla sie niepowidzeniem, sprobuj jescze raz!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}