import React, { useState } from 'react';

export default function QR() {

    const [pswd, setPswd] = useState({});

    const database = [
        {
            password: "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. \"What's happened to me?\" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather."
        }
    ];

    const errors = {
        pass: "Dobra rzecz znalezliscie na pewno?"
    };

    const renderErrorMessage = (name) =>
        name === pswd.name && (
            <div className="error">{pswd.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

        if (pass.value !== database[0].password) {
            // Invalid password
            console.log("ZLEEEEEE");
            setPswd({name: "pass", message: errors.pass});
        } else {
            console.log("GIT");
            window.location.href = "/fajerwerki";
        }

    };

    window.onload = () => {
        const myInput = document.getElementById('cantPaste');
        myInput.onpaste = e => e.preventDefault();

        myInput.setLongClickable(false);
    }

    const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault()
        alert("Wklejanie nie dziala na tej stronie, powodzonka i z fartem kurczak")
    }

    return (
        <div className="paragrafowa--content">
            <h1>Wracamy po krotkiej przerwie, to zadanie wymagac bedzie od was sprawdzenia sie w terenie!</h1>
            <p>Mianowicie waszym zadaniem jest udanie sie tutaj: 52.133770,21.052882 i znalezienie ukrytego skarbu.</p>
            <p>Co do poprzedniej historii sporem ktory sie pojawil bylo nazwanie grupy. COOOOOOOO ( nikt sie nie spodziewal takiego zwrotu akcji )...
            Odkrywcy prowadzili liczne deliberacje co poczac, ktora nazwa jest stosowna. Nie bylo to wtedy jasne dla wszystkich ale w sercach niektorych odkrycow
            powstal podzial i postanowili oni w ukryciu zachowac blizsza nazwe im sercu i pozostac wiernym Kawezedom! Co dzialo sie dalej jest juz historia pelna krwi, smiechu, kosci i ziolowych napojii</p>
            <p>Ps. Jakby z jakiegos powodu nie udawalo Wam sie znalezc skarbu na miejscu prosze dzwonic do mnie 🐔</p>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Haslo: </label>
                    <input type="text" contextMenuHidden={true} onPaste={(e) => preventCopyPaste(e)} name="pass" required className="input-text" id="cantPaste" />
                    {renderErrorMessage("pass")}
                </div>
                <div className="container">
                    <div className="button" >
                        <div className="button__line"></div>
                        <div className="button__line"></div>
                        <span onClick={handleSubmit} className="button__text">Skanuj</span>
                        <div className="button__drow1"></div>
                        <div className="button__drow2"></div>
                    </div>
                </div>
            </form>
        </div>

    );
}