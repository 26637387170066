import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Faceci() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Dzieki interwencji eksperta i grupy pasazerow w kapeluszach i bez, najnowsze prawo Jarlboya nie weszlo w zycie. Jarlboy szaleje z wscieklosci i wysyla po niego facetow w czerni - specjalny oddzial; utworzony, gdy Sluzby Szeryfowe przestaly wykonywac rozkazy zmuszajace do stosowania przemocy. Aby usprawiedliwic swoje dzialania, Jarlboy karze wyemitowac w interwizji material o tym, ze zielarstwo to sciema i go nie potrzebujemy, a zielarze to wrogowie publiczni, ktorzy chca nas otruc ziolami i przeklac rytualami. Ogladasz to razem z kolegami z klasy na przerwie w szkole. Co czujesz? Wierzysz w to? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/niespodzianka" className="button--link--parag">
                        W sumie to nigdy nie lubilem chodzic do zielarzy, zawsze byli dla mnie podejrzani! Mlodzi ludzie nie choruja, a starych u nas nie ma. Precz z zielarzami!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/niemoc" className="button--link--parag">
                        Ja moze i nie choruje, ale kiedys na Ziemi mialem babcie i pamietam, ze zielarze uratowali jej zycie, jak dostala zawalu czy czegos takiego. Mysle, ze zielarze sa dobrzy i moze jeszcze kiedys sie nam przydadza.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}