import React from "react";

export default function Fajerwerki() {

    return(
        <div className="paragrafowa--content">
            <p>Jestem leniwy (kurczak) i nie zrobilem jeszcze nic wiecej, natomiast mam jeszcze multum pomyslow na zagadki przed wielkim finalem ale nie wiem jaka jest wola w narodzie
                dlatego kto tu doszedl niech zglosi sie do mnie plox</p>
            <p>Z fartem</p>
            <p>Ps. jesli sie podoba to moge pobawic sie dalej i porobic wiecej rzeczy ale to dajcie znac moi widzowie, poki co myslalem ze po
            nastepnej zagadce ktora jest w toku bedzie koniec tej pieknej przygody i dowiecie sie w koncu o kawezedach co nieco jesli juz nie chcecie sie bawic w rozwiazywanie zagadek</p>
            <p>Pss. Aktulanie mysle tez nad stworzeniem RADIA KURCZAK</p>
        </div>
    )

}