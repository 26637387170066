import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Psycholog() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes laborantem eksploratora fal mozgowych na Knarze Kosmicznej. Zglasza sie do Ciebie Jarlboy statku. Chodzi o zarzuty na temat jego przemocowego zarzadzania statkiem. Jarlboy tlumaczy, ze zarzadzenie twarda reka to jest jedyna droga do porzadku na statku oraz jego meska powinnosc. Jest bardzo zniecierpliwiony, niechetny do wspolpracy, chce abys dal mu ulotke na temat zarzadzania bez przemocy i podpisal zaswiadczenie o odbyciu terapii bez zadnej rozmowy. Grozi Ci powaznymi konsekwencjami zawodowymi, jesli tego nie zrobisz. Jak sie zachowasz?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/okularnicy" className="button--link--parag">
                        Ulegam grozbom Jarlboya. Dopoki robi to, co do niego nalezy i prowadzi nas przez cierpienie do gwiazd jego metody sa mi obojetne, cel uswieca srodki.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/wybor" className="button--link--parag">
                        Tlumacze mu, ze prawdziwa meskosc i dojrzalosc do pelnienia odpowiedzialnych funkcji to chec pracy nad soba i umiejetnosc przyznania sie do bledow. Zapewniam go takze o 100% dyskrecji, jesli zdecyduje sie na dalsze spotkania.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}