import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import "./style.css"
import Header from "./components/fragments/Header"
import Footer from "./components/fragments/Footer";
import Main from "./components/fragments/Main";
import Rain from "./Rain"
import Kurczak from "./components/pages/games/Kurczak";
import Czekamy from "./components/pages/games/Czekamy";
import Login from "./components/pages/games/Login";
import Fajerwerki from "./components/fragments/Fajerwerki";
import Books from "./components/pages/games/Books";
import Music from "./components/pages/games/Music";
import Jutub from "./components/pages/games/Jutub";
import Password from "./components/pages/games/Password";

import Wyprawa from "./components/pages/paragrafowa/Wyprawa"
import Kapitan from "./components/pages/paragrafowa/Kapitan";
import Robotnicy from "./components/pages/paragrafowa/Robotnicy";
import Dylemat from "./components/pages/paragrafowa/Dylemat";
import Represje from "./components/pages/paragrafowa/Represje";
import Ostatnia from "./components/pages/paragrafowa/Ostatnia";
import Faceci from "./components/pages/paragrafowa/Faceci";
import Atak from "./components/pages/paragrafowa/Atak";
import Ekspertyza from "./components/pages/paragrafowa/Ekspertyza";
import Krysys from "./components/pages/paragrafowa/Krysys";
import Kultura from "./components/pages/paragrafowa/Kultura";
import Mendex from "./components/pages/paragrafowa/Mendex";
import Monitoring from "./components/pages/paragrafowa/Monitoring";
import Niemoc from "./components/pages/paragrafowa/Niemoc";
import Nienawisc from "./components/pages/paragrafowa/Nienawisc";
import Niespodzianka from "./components/pages/paragrafowa/Niespodzianka";
import Okularnicy from "./components/pages/paragrafowa/Okularnicy";
import Porzadkowa from "./components/pages/paragrafowa/Porzadkowa";
import Przewodniczaca from "./components/pages/paragrafowa/Przewodniczaca";
import Psycholog from "./components/pages/paragrafowa/Psycholog";
import Pycha from "./components/pages/paragrafowa/Pycha";
import Rada from "./components/pages/paragrafowa/Rada";
import Roboty from "./components/pages/paragrafowa/Roboty";
import Salon from "./components/pages/paragrafowa/Salon";
import Tlumacz from "./components/pages/paragrafowa/Tlumacz";
import Upadek from "./components/pages/paragrafowa/Upadek";
import Wybor from "./components/pages/paragrafowa/Wybor";
import Zaproszenie from "./components/pages/paragrafowa/Zaproszenie";
import Pswd from "./components/pages/games/Pswd";
import Morse from "./components/pages/games/Morse";
import Rome from "./components/pages/games/Rome";

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'
import QR from "./components/pages/games/QR";
import Rebus from "./components/pages/games/Rebus";


function App(){
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div>
        <Router>
            {window.location.pathname === "/findInvisibleChicken" ?  "" : <Header/>}
            <Rain />
            <Routes>
                <Route exact path="/kawezedzi" element={<Main/>} />
                <Route path="/findInvisibleChicken" element={<Kurczak/>}/>
                <Route path="/czekamy" element={<Czekamy/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/fajerwerki" element={<Fajerwerki/>}/>
                <Route path="/ksiazki" element={<Books/>}/>
                <Route path="/muzyka" element={<Music/>}/>
                <Route path="/jutub" element={<Jutub/>}/>
                <Route path="/hasloTest" element={<Password/>}/>
                <Route path="/haslo" element={<Pswd/>}/>
                <Route path="/mors" element={<Morse/>}/>
                <Route path="/rzym" element={<Rome/>}/>
                <Route path="/qr" element={<QR/>}/>
                <Route path="/rebus" element={<Rebus/>}/>

                <Route exact path="/atak" element={<Atak/>}/>
                <Route exact path="/dylemat" element={<Dylemat/>}/>
                <Route exact path="/ekspertyza" element={<Ekspertyza/>}/>
                <Route exact path="/faceci" element={<Faceci/>}/>
                <Route exact path="/kapitan" element={<Kapitan/>}/>
                <Route exact path="/kryzys" element={<Krysys/>}/>
                <Route exact path="/kultura" element={<Kultura/>}/>
                <Route exact path="/mendex" element={<Mendex/>}/>
                <Route exact path="/monitoring" element={<Monitoring/>}/>
                <Route exact path="/niemoc" element={<Niemoc/>}/>
                <Route exact path="/nienawisc" element={<Nienawisc/>}/>
                <Route exact path="/niespodzianka" element={<Niespodzianka/>}/>
                <Route exact path="/okularnicy" element={<Okularnicy/>}/>
                <Route exact path="/ostatnia" element={<Ostatnia/>}/>
                <Route exact path="/porzadkowa" element={<Porzadkowa/>}/>
                <Route exact path="/przewodniczaca" element={<Przewodniczaca/>}/>
                <Route exact path="/psycholog" element={<Psycholog/>}/>
                <Route exact path="/pycha" element={<Pycha/>}/>
                <Route exact path="/rada" element={<Rada/>}/>
                <Route exact path="/represje" element={<Represje/>}/>
                <Route exact path="/robotnicy" element={<Robotnicy/>}/>
                <Route exact path="/roboty" element={<Roboty/>}/>
                <Route exact path="/salon" element={<Salon/>}/>
                <Route exact path="/tlumacz" element={<Tlumacz/>}/>
                <Route exact path="/upadek" element={<Upadek/>}/>
                <Route exact path="/wybor" element={<Wybor/>}/>
                <Route exact path="/wyprawa" element={<Wyprawa/>}/>
                <Route exact path="/zaproszenie" element={<Zaproszenie/>}/>
            </Routes>
            {window.location.pathname === "/findInvisibleChicken" ?  "" : <Footer />}
        </Router>
        </div>

    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
