import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Ekspertyza() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes zielarka na knarze realizujacej misje Tera Con Prime. Zglasza sie do Ciebie jeden z pasazerow oburzony ostatnim zarzadzeniem Jarlboya, wedlug ktorego osoby noszace kapelusze, nie moga nosic ich w zamknietych pomieszczeniach. Jest to dyskryminacja posrednia, de facto zabraniajaca noszenia kapeluszy, w koncu knara jest jednym wielkim zamknietym pomieszczeniem, to skandal! Chce, abys jako ekspert poparla jego protest w tej sprawie. Co ciekawe, facet nie nosi nawet kapelusza (moze boi sie prac spolecznych?). </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/kultura" className="button--link--parag">
                        W glebi serca wiesz, ze to zarzadzenie to bzdura. Ale co to obchodzi faceta bez kapelusza?! Przyszedl ci tu chyba narobic problemow.  Wyganiasz go z gabinetu.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/faceci" className="button--link--parag">
                        Oczywiscie, ze popierasz ten protest. Jarlboy nie ma prawa zmuszac ludzi do takich rzeczy, to nie ma zadnego naukowego uzasadnienia!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}