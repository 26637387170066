import React from "react";
import logo from "../../img/kawezedzi_logo.png"
import test from "../../img/ornament.png"
import ol from "../../img/ornament_left.png"
import {Link} from "react-router-dom";

export default function Header(){
    return(
        <header>
            <nav className="nav">
                <img src={logo}
                alt="React Logo"
                className="nav-logo"
                />
                <img src={ol}
                     alt="ornament left"
                     className="ornament--logo"
                />
                <h3><Link to="/kawezedzi" className={"link--kawezedi"}>Kawezedzi</Link></h3>
                <img src={test}
                     alt="test Logo"
                     className="ornament--logo"
                />
                <img src={logo}
                     alt="React Logo"
                     className="nav-logo"/>
            </nav>
        </header>
    )
}