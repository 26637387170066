import React, {useState} from "react";
import books from "../../../img/books2.png";
import {Link} from "react-router-dom";

export default function Books() {

    // React States
    const [isLong, setisLong] = useState({});

    // User Login info
    const database = [
        {
            password: "CDSRM"
        }
    ];
    const errors = {
        pass: "zle haslo do pulpitu"
    };


    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error">{isLong.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

            if (pass.value !== database[0].password) {
                // Invalid password
                console.log("ZLEEEEEE ale skoto tutaj zagladasz to podpowiadam ze w sekcji head w inspektorze");
                setisLong({name: "pass", message: errors.pass});
            } else {
                console.log("GIT");
                window.location.href = "/muzyka";
            }

    };

    return(
        <div className="paragrafowa--content">
            <div>Udalo Wam sie zalogowac! Ale niestety zeby odblokowac oslony planety musicie przejsc przez dalsze zabezpieczenia.</div>
            <div>Najpierw trzeba wpisac jakies haslo.</div>
            <div>Baltazar w pospiechu zostawil na pulpicie jakies zdjecie z zapiskami oraz okladkami ksiazek. Najwidoczniej sa potrzebne zeby wpisac haslo i przejsc dalej. Wam tez sie uda?</div>
            <br></br>
            <img src={books}
                 alt="React Logo"
                 className="book-img"
            />
            <div className="form">
                <form>
                    <div className="input-container">
                        <label>Haslo </label>
                        <input type="password" name="pass" required />
                        {renderErrorMessage("pass")}
                    </div>
                    <div className="container">
                        <div className="button" >
                            <div className="button__line"></div>
                            <div className="button__line"></div>
                            <span onClick={handleSubmit} className="button__text">Potwierdz haslo</span>
                            <div className="button__drow1"></div>
                            <div className="button__drow2"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

}