import React, {useState, useEffect} from "react";


export default function Morse() {

        const [isBlack, setIsBlack] = useState(true);
    const [isLong, setisLong] = useState({});

    const database = [
        {
            password: "kurczakiziemniaki"
        }
    ];
    const errors = {
        pass: "ZLEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
    };

        //-.-   ..-  .-.   -.-. --..   .-   -.-   ..   --..  ..  .   --   -.  ..   .-  -.-  ..       kurczaki ziemniaki
        // k   u      r     c    z     a     k    i     z    i   e   m    n   i    a   k    i

    //pierwsza black a pozniej co druga tak samo, jest 87 zmian
    const black = 1000;
    const waiting = 5000;
    const intervals =
        [black, 2000, black, 500, black, 2000, waiting,
            500, black, 500, black, 2000, waiting,
            500, black, 2000, black ,500,waiting,
            2000,black,500,black,2000,black,500,waiting,
            2000,black,2000,black,500,black,500,waiting,
            500,black,2000,waiting,
            2000,black,500,black,2000,waiting,
            500,black,500,waiting,
        //powyzej jest kurczaki
            2000,black,2000,black,500,black,500,waiting,
            500,black,500,waiting,
            500,waiting,
            2000,black,2000,waiting,
            2000,black,500,waiting,
            500,black,500,waiting,
            500,black,2000,waiting,
            2000,black,500,black,2000,waiting,
            500,black,500
        //powyzej jest ziemniaki
        ];

    const intervalCount = intervals.length;

    useEffect(() => {
        let currentIndex = 0;
        let timeoutId;

        const changeColor = () => {
            setIsBlack(prevIsBlack => !prevIsBlack);
            currentIndex = (currentIndex + 1) % intervalCount;
            console.log("black " + currentIndex)
            timeoutId = setTimeout(changeColor, intervals[currentIndex]);
        };

        timeoutId = setTimeout(changeColor, intervals[currentIndex]);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error">{isLong.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

        if (pass.value !== database[0].password) {
            // Invalid password
            console.log("ZLEEEEEE");
            setisLong({name: "pass", message: errors.pass});
        } else {
            console.log("GIT");
            window.location.href = "/rzym";
        }

    };

        return (
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: isBlack ? 'black' : 'white',
                    color: isBlack ? 'white' : 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '24px',
                }}
            >
                <div className="form">
                    <form onSubmit={handleSubmit}>
                        <div className="input__mors">
                            <input type="text" name="pass" required className=""/>
                            {renderErrorMessage("pass")}
                        </div>
                        <div className="container">
                            <div className="button" >
                                <div className="button__line"></div>
                                <div className="button__line"></div>
                                <span onClick={handleSubmit} className="button__text">Potwierdz</span>
                                <div className="button__drow1"></div>
                                <div className="button__drow2"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );

}