import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Wybor() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jarlboyu, gratulacje trafiles do gabinetu laboranta eksploratora fal mozgowych i masz szanse pomoc samemu sobie. Bedzie to jednak praca trudna, dlugotrwala i nie zawsze przyjemna. Jednak Twoja postawa jest w stanie uratowac cala misje: dotrzec do planety Tera Con Prime i uczyc sie od kawezedow rzeczy o ktorych filozofom sie nie snilo, moze nawet dowiesz sie, gdzie pieprz rosnie? Aby skupic sie na terapii i popracowac nad swoja agresja, musisz na jakis czas ustapic ze stanowiska Jarlboya. Co czujesz, myslisz i co ostatecznie zrobisz?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/przewodniczaca" className="button--link--parag">
                        Decyduje sie wspolpracowac, aby naprawic siebie i dopiac celu naszej misji. Jest to jednak trudne, bo wymaga przyznania sie do bledow i pokonania wstydu.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/okularnicy" className="button--link--parag">
                        Nikt nie bedzie robic ze mnie wariata. Po moim trupie!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}