import React, {useState} from "react";

export default function Password() {

    // React States
    const [isLength, setIsLength] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [isDigit, setIsDigit] = useState(false);
    const [isUpperCase, setIsUpperCase] = useState(false);
    const [color, setColor] = useState('#e27d60');


    const changeTextColor = (name) => {
        const randomColor = `greenyellow`;

        const element = document.getElementById(name);
        if (element) {
            element.style.color = randomColor;
        }
    };

    function addLengthError() {
        return(
            <div className="error" id="length" style={{color: color }}>
                Haslo musi skladac sie z przynajmniej 5 znakow
            </div>
        )
    }

    function addError() {
        return(
            <div className="error" id="big" style={{color: color }}>
                Haslo musi zawierac duza litere
            </div>
        )
    }
    function addDigitError() {
        return(
            <div className="error" id="digit" style={{color: color }}>
                Haslo musi zawierac liczbe
            </div>
        )
    }
    function addSpecialError() {
        return(
            <div className="error" id="special" style={{color: color }}>
                Haslo musi zawierac znak specjalny
            </div>
        )
    }


    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var tst = document.getElementById("pass").value;
        var counter = 0;

        //min długośśc
        const minLengthRegExp   = /.{5,}/;  //dlugosc 5
        const minLengthPassword =   minLengthRegExp.test(tst);

        //uppercase
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const uppercasePassword =   uppercaseRegExp.test(tst);

        //digits
        const digitsRegExp      = /(?=.*?[0-9])/;
        const digitsPassword =      digitsRegExp.test(tst);

        //special char
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const specialCharPassword = specialCharRegExp.test(tst);

        //min długość hasla
        if(!minLengthPassword){
            console.log("ZA KROTKIE")
            setIsLength(true);
        }else{
            changeTextColor("length");
            counter++;
        }
        //Wielka litera
        if(!uppercasePassword && counter === 1){
            console.log("Brak dużej litery");
            setIsUpperCase(true);
        }else {
            changeTextColor("big");
            counter++;
        }
        //TODO liczba
         if(!digitsPassword && counter === 2){
            console.log("Brak liczby");
            setIsDigit(true);
             changeTextColor("big");
        } else {
             //setIsDigit(false);
             changeTextColor("digit")
             counter++;
        }

        //TODO special char
        if(!specialCharPassword && counter === 3){
            console.log("Brak znaku specjalnego");
            setIsSpecial(true);
        } else {
            changeTextColor("special");
            //setIsSpecial(false);
            counter++;
        }
        console.log(counter + " taka jest licza aktualnie")

        //TODO liczby dodawać się do 25

        //TODO month of the year

        //TODO romanian numeral

        //TODO sponosor

        //TODO romanian to 35 multiply

        //TODO captcha???

    }

    return(
        <div className="paragrafowa--content">
            <div>Sprobuj Podac haslo</div>
            <div className="form">
                <form>
            <div className="input-container">
                <label>Haslo: </label>
                <input type="text" name="pass" required className="input-text" id="pass"/>
            </div>
                    <div className="App">
                        {isLength && addLengthError()}
                        {isUpperCase && addError()}
                        {isDigit && addDigitError()}
                        {isSpecial && addSpecialError()}
                    </div>
            <div className="container">
                <div className="button" >
                    <div className="button__line"></div>
                    <div className="button__line"></div>
                    <span onClick={handleSubmit} className="button__text">Przetworz dane</span>
                    <div className="button__drow1"></div>
                    <div className="button__drow2"></div>
                </div>
            </div>
                </form>
            </div>
        </div>
    )

}