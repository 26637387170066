import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Roboty() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes inzynierka kompatybilnosci wstenczej na knarze kosmicznej realizujacej misje Tera Con Prime. Juz za dwa lata ladujecie. Od jakiegos czasu nie ruszasz sie z kabiny, bo na pokladzie nie czujesz sie bezpiecznie. Od czasu, gdy zniknela kultura i pojawily sie w to miejsce kluby walki, coraz czesciej dochodzilo do rekoczynow miedzy ludzmi. Przemoc z wirtualnej rzeczywistosci stala sie codziennoscia. Masz wrazenie, ze ludzie zupelnie zapomnieli, po co lecicie na nowa planete. Czytasz sobie e-booka, gdy nagle dzwoni do Ciebie numer z interwizji. Sprzedawca w sluchawce oferuje Ci najnowszy model robota do bicia. Jest to upowszechniony ostatnimi czasy wynalazek, dzis do nabycia w promocyjnej cenie. Mozna go bic calkiem jak czlowieka, ale poniewaz to robot nie odczuwajacy bolu ani emocji, bicie go jest zupelnie humanitarne i nieszkodliwe. Kupujesz? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/ostatnia" className="button--link--parag">
                        Czemu nie, w koncu taka okazja! Troche ruchu mi sie przyda, a moze i naucze sie kilku ciosow na tych osilkow, przez ktorych nie wychodze z kabiny... Skoro wszyscy to robia, to nie bede ostatnia frajerka siedzaca w strachu.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/mendex" className="button--link--parag">
                        To jest skandal! Nie wolno bic nikogo, nawet robota! A ja bede bronila naszej pokojowej misji, chocbym zostala ostatnia sprawiedliwa!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}