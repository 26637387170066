export default function Rain() {
    let hrElement;
    let counter = 100;
    if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        for (let i = 0; i < counter; i++) {
            hrElement = document.createElement("HR");
            if (i === counter - 1) {
                hrElement.className = "thunder";
            } else {
                hrElement.style.left = Math.floor(Math.random() * window.innerWidth) + "px";
                hrElement.style.animationDuration = 0.2 + Math.random() * 0.3 + "s";
                hrElement.style.animationDelay = Math.random() * 5 + "s";
            }
            document.body.appendChild(hrElement);
        }
    }
    console.log(
        "There are " +
        document.querySelectorAll("hr").length +
        " <hr> tags in this project :) " + window.innerWidth
    );
}