import React from "react";
import { Link} from "react-router-dom";

export default function Main(){

    function refreshPage() {
        window.location.reload(false);
    }

    return(
        <div className= 'main-content'>
            <h1>Kawezedzi zapytacie?</h1>
            <p>Nie wszystkie wyzwania beda dzialac na telefonie, uzywanie komputera jest wskazane</p>
            <p>Zaczelo sie to dawno temu...</p>
            <p>Kiedy to swiatem rzadzily prostsze zasady, kiedy kazdy smiertelnik wpatrzony w gwaizdy odliczal do dziecieciu krokow nim los zdecydowal czyje cialo zbruka deski knary</p>
            <p>Czy jestescie ciekawi czemu Andrzej to debil ze zdradzil Wam kiedys ta nazwe? Chcielibyscie wiedziec kim sa kawezedzi i dlaczego potrzebuja nagle waszej pomocy, a moze po prostu pierwszy raz slyszycie ta nazwe i chcielbyscie dowiedziec sie czegos wiecej?</p>
            <p>Jesli jestescie gotowi wyruszyc w podroz ktora pomoze wam zrozumiec nie tylko kim byli kawezedzi ale rowniez siebie, a zarazem sprawdzi czy jesteci godni tej wiedzy?</p>
            <div className="container">
                <p className="button">
                    <div className="button__line"></div>
                    <div className="button__line"></div>
                    <span onClick={refreshPage} className="button__text"><Link to="/findInvisibleChicken" className = "button--link">Probojecie?</Link></span>
                    <div className="button__drow1"></div>
                    <div className="button__drow2"></div>
                </p>
            </div>
        </div>
    )
}