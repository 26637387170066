import React, {useState} from "react";
import {Link} from "react-router-dom";

export default function Login() {

    // React States
    const [isLong, setisLong] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // User Login info
    const database = [
        {
            username: "Baltazar",
            password: "Wielki"
        },
        {
            username: "user2",
            password: "pass2"
        }
    ];

    const errors = {
        uname: "zly login",
        pass: "zle haslo"
    };

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];

        // Find user login info
        const userData = database.find((user) => user.username === uname.value);

        // Compare user info
        if (userData) {
            if (userData.password !== pass.value) {
                // Invalid password
                setisLong({ name: "pass", message: errors.pass });
            } else {
                setIsSubmitted(true);
                window.location.href = "/ksiazki"
            }
        } else {
            // Username not found
            setisLong({ name: "uname", message: errors.uname });
        }
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error">{isLong.message}</div>
        );

    return (
        <div className="form">
            <div>Widzicie juz nasza piekna planeta przez batyskaf waszego statku kosmicznego.
                Niestety zeby moc wyladawac musicie najpierw zalogowac sie do naszego systemu obrony planetarnej.
                <br></br>Podajcie ponizej dane logowania naszego "glownego ludzia pilnujacego kamieni astralnych" w skorcie GLUPKA
            </div>
            <form>
                <div className="input-container">
                    <label>Login </label>
                    <input type="text" name="uname" required />
                    {renderErrorMessage("uname")}
                </div>
                <div className="input-container">
                    <label>Haslo </label>
                    <input type="password" name="pass" required />
                    {renderErrorMessage("pass")}
                </div>
                <div className="container">
                    <div className="button" >
                        <div className="button__line"></div>
                        <div className="button__line"></div>
                        <span onClick={handleSubmit} className="button__text">Zaloguj</span>
                        <div className="button__drow1"></div>
                        <div className="button__drow2"></div>
                    </div>
                </div>
            </form>
            <div>Jesli ta zagadaka jest dla Was zbyt ciezka moi wysalnnicy to zapraszam do mojego wiernego pomocnika Andrzeja on cos wskora.</div>
            <br></br>
            <div>PS. chochliki zniszczyly szate graficzna na tej i kolejnych stronach, prace w toku nad naprawa tej katastrofy!</div>
        </div>
    );
}