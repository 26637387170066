import React from "react";
import c_white from "../../img/c_white.png"

function Footer() {
    return (

        <footer className="font-link-odin">
            <img src={c_white}
                 alt="copywright"
                 className="c-logo"/>
            pokorny sluga Baltazar
        </footer>
    )
}

export default Footer