import React, {useState} from "react";

export default function Rebus() {

    const [isLong, setisLong] = useState({});

    const database = [
        {
            password: "carbonara"
        }
    ];

    const errors = {
        pass: "Zle nie tak nalezy rozwizac ten rebus"
    };

    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error">{isLong.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

        if (pass.value !== database[0].password) {
            // Invalid password
            console.log("ZLEEEEEE");
            setisLong({name: "pass", message: errors.pass});
        } else {
            console.log("GIT");
            window.location.href = "/qr";
        }

    };

    return(
        <div className="paragrafowa--content">
            <p>Co tu duzo mowic oszukalsmy was to nie jest ostania zagadka ale niestety w zyciu nalezy spodziewac sie rzeczy niespodziwanych.</p>
            <p>Za to dostaniecie krotki wstep do histori kawezedow. Zaczelo sie to wszystko w 2017 roku  (tylko ktorej ery????).
                Zebrala sie wtedy grupa odkrywcow, przodownikow pracy, prawdziwych bohaterow jakich ten swait nigdy nie widzial. Zostali oni postawieni przed ogromnym wyzwaniem!
            Mieli za zadanie nadac nazwe pewnej grupie, grupie niezwyklej kotra miala w przyszlosci osiagnac ogromne rzeczy. Odkrywcy mieli wiele sporow i jednym z nich stalismy sie My.
                Jesli chcesz poznac dalsze przygody odkrywcow wpisz odpowiedz na ponizszy rebus zeby przejsc dalej -= </p>
            <p>🚗  +  bo  +  👋</p>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Haslo: </label>
                    <input type="text" name="pass" required className="input-text"/>
                    {renderErrorMessage("pass")}
                </div>
                <div className="container">
                    <div className="button" >
                        <div className="button__line"></div>
                        <div className="button__line"></div>
                        <span onClick={handleSubmit} className="button__text">Zatwierdz</span>
                        <div className="button__drow1"></div>
                        <div className="button__drow2"></div>
                    </div>
                </div>
            </form>
        </div>
    )

}