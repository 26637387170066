import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Niespodzianka() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes Jarlboyem Knary Kosmicznej. Misja TERA CON PRIME trwa juz 9 lat, czyli dokladnie polowe. Moze nie wszystko idzie zgodnie z planem, bo co i rusz ktos cie denerwuje i musisz go wykluczac z zycia publicznego, ale cieszysz sie swoja wladza i jestes dumny, ze z kazdym problemem radzisz sobie tak sprawnie. Ludzie musza czuc respekt do Jarlboya. Idac glownym korytarzem statku, nie rozgladasz sie na boki, bo zobaczylbys tam plakaty ze swoja karykatura. Na szczescie udalo ci sie juz ukarac ich autorow. Zaopatrzeniowcy, ktorzy przynosza Ci skladniki odzywcze, musza robic to w maskach i rekawiczkach. Jeden zbyt gleboki oddech i laduja w zamknietym pokladzie. W swojej kabinie masz prawdziwy spokoj: kiedy pragniesz odwiedza Cie robot do bicia, a poza tym mozesz rozkoszowac sie bloga samotnoscia. Dzis wracajac z porannego apelu znajdujesz czerwone serduszko w swoim kokpicie. Otwierasz je odruchowo, a z wnetrza wydobywa sie rozowy proszek. Oblepia twoja twarz, ktora momentalnie pokrywa sie bablami, podobnie dlonie. Wybiegasz z kokpitu, krzyczac i rozsypujac wokol trujaca substancje. Wszyscy ludzie, ktorych wywabily z kabin Twoje wrzaski, momentalnie dostaja podobnych babli od rozsypanej trucizny. Za chwile wszystkich dosiegnie smiertelna epidemia. Co robisz?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/przewodniczaca" className="button--link--parag">
                        Kaze uwolnic medykow i podaje sie do dymisji
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/nienawisc" className="button--link--parag">
                        Covid nie dal rady to moze ta zaraza dokonczy dziela.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}