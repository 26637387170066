import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from 'react';

export default function Wyprawa(){

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p >Jak juz wiecie zostalismy zaatakowani i jestescie nasza jedyna nadzieja na ratunek.
                smiemy przypuszczac kto nas zaatakowal ale jest jeszcze za wczesnie zeby rzucac tak powaznymi oskarzeniami bez dowodow
                (ostatnie czego teraz nam trzeba to kolejny najezdzca u naszych wrot),
                dlatego jesli potwierdza sie nasze podejrzenia setki golebi ruszy by dostarczyc Wam ta wiadomosc.
                Tymczasem domniemanym powodem dla ktorego zostalismy zaatakowani jest nasza wiedza i to co dzieki niej stworzylismy.
                Dlatego tez nie mozemy w prosty sposob sie z wami skomunikowac. Czekaja Was z tego powodu liczne wyzwania, proby oraz zagadki ktorych nie powstydzilaby sie sama
                Zalberta Wielka, ktorych tez niekiedy sami nie bedziecie w stanie rozwiazac i wtedy tylko o wspolnych silach zdolacie stawic im czola.
                Dlatego tez zostaniecie teraz poddani probie czy Wasze serca sa tak czyste jak mowia o nich legendy! Waszym zadaniem jest znalezienie planety Tera Con Prime w sektorze 18650 na ktorej aktualnie sie znajdujemy.
                Jestescie elita, nasza nadzieja dlatego nie czekajcie dluzej spojrzcie po raz ostatni na pola u waszych stop i ruszajcie!</p>
            <div className="container">
                <p className="button">
                    <div className="button__line"></div>
                    <div className="button__line"></div>
                    <span className="button__text"><Link to="/kapitan" className = "button--link">Per Aspera Ad Astra</Link></span>
                    <div className="button__drow1"></div>
                    <div className="button__drow2"></div>
                </p>
            </div>
        </div>
    )
}