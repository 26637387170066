import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Zaproszenie() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes jednym z pasazerow misji TERA CON PRIME. Przed chwila spotkaliscie rodzine mieszkancow Mendexu - to najbardziej ugodowe, zyjace od tysiecy lat w pokoju i wzajemnym szacunku istoty w przestrzeni kosmicznej. Mozecie sie wiele od nich nauczyc o podrozy miedzygwiezdnej, o tym gdzie raki zimuja i dokad noca tupta jez. Co wiecej - Mendexianie okazali sie przyjaciolmi kawezedow i znaja miedzygalaktyczny skrot, ktorym dotrzecie na ich planete w niecaly rok, jesli tylko posluchacie ich wskazowek i beda mogli Wam zaufac. Nie zmarnujcie tego! Powodzenia!</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/czekamy" className="button--link--parag">
                        Udalo sie! Dotarlismy do Tera con Prime!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}