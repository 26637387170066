import React from "react";
import { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

const Timer = () => {

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = "July, 24, 2023";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    if(days && hours && minutes && seconds<0){
        return (
            <div><p>Gratulacje jako jedni z nielicznych dotarliscie do konca tej wyprawy</p>
                <p>Niestety to nie koniec testow oraz prob, bo przed Wami oto nasza Planeta</p>
            <button className="timer--button">
                <Link to="/login" className="button--link--parag">
                GO GO GO </Link>
                <span className="first"></span>
                <span className="second"></span>
                <span className="third"></span>
                <span className="fourth"></span>
            </button>
            </div>
        )
    }
    return (
        <div>
            <p>Niestety ulozenie gwiazd nie sprzyja.
            Wrocicie kiedy nastanie odpoweidni czas:</p>
        <div className="timer" role="timer">
            <div className="col-4">
                <div className="box">
                    <p id="day" className="text">{days < 0 ? "0"  : days}</p>
                    <span className="text">Dni</span>
                </div>
            </div>
            <div className="col-4">
                <div className="box">
                    <p id="hour" className="text">{hours < 0 ? "0"  : hours}</p>
                    <span className="text">Godzin</span>
                </div>
            </div>
            <div className="col-4">
                <div className="box">
                    <p id="minute" className="text">{minutes < 0 ? "0"  : minutes}</p>
                    <span className="text">Minut</span>
                </div>
            </div>
            <div className="col-4">
                <div className="box">
                    <p id="second" className="text">{seconds < 0 ? "0"  : seconds}</p>
                    <span className="text">Sekund</span>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Timer;
