import React from 'react';

// Calculate the moon phase based on a given date
function calculateMoonPhase(date) {
    const SYNODIC_MONTH = 29.53058867; // Average synodic month length in days

    // Calculate the number of days since the last known new moon (January 6, 2000)
    const daysSinceNewMoon = (date - new Date('2000-01-06')) / (24 * 60 * 60 * 1000);

    // Calculate the moon age (phase)
    const moonAge = daysSinceNewMoon % SYNODIC_MONTH;

    // Determine the moon phase based on the moon age
    if (moonAge < SYNODIC_MONTH * 0.0625 || moonAge > SYNODIC_MONTH * 0.9375) {
        return 'New Moon';
    } else if (moonAge < SYNODIC_MONTH * 0.1875) {
        return 'Waxing Crescent';
    } else if (moonAge < SYNODIC_MONTH * 0.3125) {
        return 'First Quarter';
    } else if (moonAge < SYNODIC_MONTH * 0.4375) {
        return 'Waxing Gibbous';
    } else if (moonAge < SYNODIC_MONTH * 0.5625) {
        return 'Full Moon';
    } else if (moonAge < SYNODIC_MONTH * 0.6875) {
        return 'Waning Gibbous';
    } else if (moonAge < SYNODIC_MONTH * 0.8125) {
        return 'Last Quarter';
    } else {
        return 'Waning Crescent';
    }
}

const moonPhasesEmojis = {
    'New Moon': '🌑',
    'Waxing Crescent': '🌒',
    'First Quarter': '🌓',
    'Waxing Gibbous': '🌔',
    'Full Moon': '🌕',
    'Waning Gibbous': '🌖',
    'Last Quarter': '🌗',
    'Waning Crescent': '🌘',
};

const GetCurrentMoonPhase = () => {
    const date = new Date(); // Use the current date
    const moonPhase = calculateMoonPhase(date);
    const moonPhaseEmoji = moonPhasesEmojis[moonPhase];
    // Return moon phase and emoji as a string
    return `${moonPhaseEmoji}`;
};

export default GetCurrentMoonPhase;