import React, {useState} from "react";
//sound
import test from "../../../sound/AM.mp3";
import rickS from "../../../sound/Rick.mp3";
import ale from "../../../sound/ale.mp3";
import dame from "../../../sound/Dame.mp3";
import gorilaz from "../../../sound/Gorillaz - DARE (Official Video).mp3";
import sukienkaS from "../../../sound/Long & Junior - Kolorowa Sukienka - Official Video Clip.mp3";
import meduzaS from "../../../sound/meduza.mp3";
import szklanki from "../../../sound/Young Leosia - Szklanki.mp3";
//img
import alejazz from "../../../img/ale_jazz.png";
import abba from "../../../img/abba1.jpg";
import gorillaz from "../../../img/gorillaz.PNG"
import am from "../../../img/am.jpg"
import rick from "../../../img/rick.png"
import sukienka from "../../../img/sukienka.jpg"
import meduza from "../../../img/meduza.jpg"
import leosia from "../../../img/leosia.jpg"

export default function Music() {

    // React States
    const [isLong, setisLong] = useState({});

    // User Login info
    const database = [
        {
            password: "woofupkwaqurmuumrowuahahmiau"
        }
    ];
    const errors = {
        pass: "Nie chce nic mowic ale nooooo nie taka kolejnosc"
    };


    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error">{isLong.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

        if (pass.value !== database[0].password) {
            // Invalid password
            console.log("ZLEEEEEE");
            setisLong({name: "pass", message: errors.pass});
        } else {
            console.log("GIT");
            window.location.href = "/haslo";
        }

    };
    //TODO CSS
    //TODO 2 czy zmienic ustawienie tych obrazkow ???
    return(
        <div className="form">
            <div>Nie wiem kto to wymyslil ale najwidoczniej nie kazda zyjaca istota jest w stanie przetwazac dzwiek jak kawezedzi.
                Dlatego kolejnym Waszym zadaniem jest dopasowanie utworow w
                odpowiedniej kolejnosci zebysmy mogli przejsc dalej w naszym cudownym systemie obrony planetarnej.</div>
            <div>W tym celu nalezy ulozyc w odpowiedniej kolejnosci tekst znajdujacy sie obok obrazkow i wpisac go ponizej.</div>
            <br></br>
            1.
            <audio
                   controls
                   id="audioFar"
                   src={meduzaS}>
            </audio>

            <br></br>
            2.<audio
            controls
            id="audioFar"
            src={rickS}>
        </audio>

            <br></br>
            3.<audio
            controls
            id="audioFar"
            src={ale}>
        </audio>

            <br></br>
            4.<audio
            controls
            id="audioFar"
            src={dame}>
        </audio>

            <br></br>
            5.<audio
            controls
            id="audioFar"
            src={gorilaz}>
        </audio>

            <br></br>
            6.<audio
            controls
            id="audioFar"
            src={szklanki}>
        </audio>

            <br></br>
            7.<audio
            controls
            id="audioFar"
            src={test}>
        </audio>

            <br></br>
            8.<audio
            controls
            id="audioFar"
            src={sukienkaS}>
        </audio>
            <br></br>
            <img id="img" src={gorillaz}  className="music-logo" alt="muu"/>
            <img id="img" src={am}  className="music-logo" alt="logo"/>
            <img id="img" src={leosia}  className="music-logo" alt="logo"/>
            <img id="img" src={sukienka}  className="music-logo" alt="logo"/>
            <img id="img" src={rick}  className="music-logo" alt="logo"/>
            <img id="img" src={abba}  className="music-logo" alt="qur"/>
            <img id="img" src={meduza}  className="music-logo" alt="logo"/>
            <img id="img" src={alejazz}  className="music-logo" alt="kwa"/>
            <div className="form">
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label>Haslo: </label>
                        <input type="text" name="pass" required className="input-text"/>
                        {renderErrorMessage("pass")}
                    </div>
                    <div className="container">
                        <div className="button" >
                            <div className="button__line"></div>
                            <div className="button__line"></div>
                            <span onClick={handleSubmit} className="button__text">Przetworz dane</span>
                            <div className="button__drow1"></div>
                            <div className="button__drow2"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

}