import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Tlumacz() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Przybysze z Planety MENDEX mowia, ze zostali wezwani, aby pomoc Wam w zbudowaniu nowej cywilizacji opartej na szacunku, bezpieczenstwie i milosci. Chetnie wskaza Wam droge na upragniona planete Tera Con Prime. Zacznijcie od tego, zeby posluchac ich rad dotyczacych komunikacji bez przemocy. Jako mediator i tlumacz jezyka kosmicznego co robisz?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/atak" className="button--link--parag">
                        Przekazujesz wiesci Jarlboyowi oraz Radzie Statku zeby podjeli ostateczna decyzje.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/zaproszenie" className="button--link--parag">
                        Przekazujesz tresc wiadomosci wszystkim pasazerom, by wspolnie na wiecu podjac decyzje.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}