import React from "react";
import Timer from "../../fragments/Timer";

export default function Czekamy() {

    return(
        <div className="paragrafowa--content">
            <Timer></Timer>
        </div>
    )

}