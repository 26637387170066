import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from 'react';

export default function Kapitan(){



    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);



    return(
            <div className="paragrafowa--content--kapitan">
                <p>Jestes Jarlboyem tej pieknej knary kosmicznej. Pod swoimi strzemionami posiadasz 35 najlepiej wyszkolonych ludzi ktorych kazdy by Ci pozazdroscil. Niestety sektor 18650 nie jest jednym z najblizszych naszej Ziemi, minelo juz 5 cykli, a przed toba kolejne zmudne 13… Jak dotad kazdy dzien byl zwyczajny nie rozniacy sie od poprzedniego, przez co coraz czesciej dochodza Cie glosy niezadowolenia i frustracji na pokladzie. Otrzymales wlasnie zapieczotowana wiadomosc inforumjaca Cie ze pracownicy dzialu zaopatrzenia groza buntem, rebelia! Co Robisz? </p>
                <div className="cont">
                    <button className="timer--button">
                        <Link to="/robotnicy" className="button--link--parag">
                            Zapraszam ich do rozmowy by dowiedziec co sie stalo i jak im pomoc by mogli wrocic do pracy.
                        </Link>
                        <span className="first"></span>
                        <span className="second"></span>
                        <span className="third"></span>
                        <span className="fourth"></span>
                    </button>
                </div>
                <div className="cont">
                    <button className="timer--button">
                        <Link to="/porzadkowa" className="button--link--parag">
                            Przekazuje Sprawe Sluzbie Szeryfowej,  zeby spacyfikowali rebeliantow.
                        </Link>
                        <span className="first"></span>
                        <span className="second"></span>
                        <span className="third"></span>
                        <span className="fourth"></span>
                    </button>
                </div>
            </div>
    )
}
