import React, {useEffect, useRef} from "react";
import { useState } from 'react';
import kurczak from "../../../img/kurczak.jpg"
import sound from "../../../sound/Kurczak2.m4a"
import {Link} from "react-router-dom";

export default function Kurczak() {

    function refreshPage() {
        window.location.reload(false);
    }

    const [coord, setCoords] = useState({x: 0, y: 0});
    var audioFar = document.getElementById("audioFar")

    useEffect(() => {
        const handleWindowMouseMove = event => {
            setCoords({
                x: event.clientX,
                y: event.clientY,
            });
        };
        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
        };
    }, []);

    //dzwiek  zalezny od odleglosci myszki od obrazka
    window.addEventListener("mousemove", (e) =>{
        var kurczakImg = document.getElementById("img")
        var positionX = (parseInt(getOffset(kurczakImg).left) - parseInt(coord.x))
        var positionY = (parseInt(getOffset(kurczakImg).top) - parseInt(coord.y))

        var pos = Math.floor(Math.sqrt(Math.pow(positionX, 2)+ Math.pow(positionY, 2)))

        if(pos < 50){
            //TODO
            //zastawnoić się czy nie wstawić tutaj jakiegoś krzyku np AAAAAA
        }
        if(pos < 100){
            audioFar.volume = 1
            audioFar.playbackRate = 2.5
        }
        if(pos > 100 && pos < 300){
            audioFar.volume = 0.8
            audioFar.playbackRate = 2
        }
        if(pos > 300 && pos < 500){
            audioFar.volume = 0.5
            audioFar.playbackRate = 1.5
        }
        if(pos > 500){
            audioFar.volume = 0.2
            audioFar.playbackRate = 1
        }

    });


    //styl dla niewidzalnego obrazka
        const ChipStyles = useRef({
            position: 'absolute',
            opacity: 0,
            top: Math.floor(Math.random() * window.innerHeight),
            left: Math.floor(Math.random() * window.innerWidth),
            transform: 'translate(-50%, -50%)'
        });


    //ukrywanie/pokazywanie obrazka na kliknieciu
    function hide(){
        var getImg = document.getElementById("img")
        if(getImg.style.opacity === "0"){
            getImg.style.opacity = "100%";
            audioFar.pause()
            win()
        } else {
            getImg.style.opacity = "0";
        }
    }

    //pobieranie pozycji obrazka
    function getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    //sleep
    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    //funkcja powinna puszczac dzwiek na załadowaniu strony
    window.onload = function() {
        var btn = document.getElementById("end")
        //new Audio(sound).play();
        btn.style.display = "none"
    }

    //przycisk uruchamia grę
    function play(){
        var przysickStartu = document.getElementById("myAudio")
        audioFar.play()
        przysickStartu.style.display = "none"
    }

    //animacja po kliknieciu obrazka
    async function win(){
        var img = document.getElementById("img")
        var btn = document.getElementById("end")
        var left = ((window.innerWidth/2) - getOffset(img).left)
        const top = ((window.innerHeight/2) - getOffset(img).top)

        await timeout(1000);
        // Animation effect
        img.style.transition = "transform 2.25s ease";
        //moje
        img.style.transform = `translate(${left}px, ${top}px) rotate(785.398163397rad) scale(5)`;
        await timeout(5000);
        img.style.display = "none";
        btn.style.display =  "block";
    }

    //zmienic na obrazek kurczaka a moze moje zdj?
    //TODO
    //zmienic przejscie z ostatniego przycisku
        return (
            <div>
                <img id="img" src={kurczak} style={ChipStyles.current} className="kurczak-logo" alt="logo" onClick={hide}/>
                <div className="timer--btn">
                <button id="myAudio" className="timer--button"  onClick={play}>
                    Ale zanim wyruszycie musicie znalezc niewidzalnego kurczaka za pomoca dzwieku!
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
                </div>
                <audio loop
                       id="audioFar"
                       src={sound}>
                </audio>
                <div className="timer--btn">
                <button  onClick={refreshPage} id="end" className="timer--button--end">
                    <Link to="/wyprawa" className="button--link--parag" >
                    Udalo ci sie! Czas ruszac dalej.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
                </div>
                </div>
        );
}


