import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Represje() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes robotnikiem - zaopatrzeniowcem na knarze kosmicznej. Chwile temu razem ze wspolpracownikami wywalczyliscie naprawe klimatyzacji, bez ktorej ciezko bylo Wam pracowac. Niestety w odwecie za buntowanie sie wlasnie odebrano Ci mozliwosc brania udzialu w koncertach, spektaklach i pokazach filmowych na knarze.</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/okularnicy" className="button--link--parag">
                        Czuje sie upokorzony, ale koledzy powstrzymuja mnie od zemsty. Moowia, ze juz lepiej przetrwac i robic swoje, kara wlasciwie nie jest az taka surowa. Mozna powiedziec ze jest medium rare.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/rada" className="button--link--parag">
                        Jestem wsciekly na to nieludzkie traktowanie. Wspolnie z czescia zalogi piszemy odwolanie do Rady Statku. Nie zrobilismy nikomu nic zlego!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}