import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Mendex() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes Pelegrynem, mieszkancem planety MENDEX, wlasnie wraz z rodzina wybraliscie sie na wakacje w strone planety Ziemia, chcecie zobaczy Slonce noca i inne atrakcje okolicy. Tymczasem zbliza sie do Was niezidentyfikowany obiekt latajacy nadajacy sygnaly po ziemsku. Co robicie? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/tlumacz" className="button--link--parag">
                        Nic sie nie da zrobic, Ziemianie to straszni agresorzy. Zniszczyli swoja planete, a teraz chca kolonizowac reszte kosmosu. Moze jednak pozwola sobie jakos pomoc?
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/monitoring" className="button--link--parag">
                        Wiemy ze Ziemianie sa nieprzewidywalni i agresywni. A przynajmniej tak bylo na filmach. Jednak my jestesmy pacyfistami, nie bierzemy udzialu w wojnach, zawsze jestesmy otwarci. Oswietlamy nasz smoko-pojazd w ksztalcie delfina na rozowo, aby pokazac ziemianom, ze nasza misja jest milosc.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}