import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Okularnicy() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes jednym z pasazerow Knary kosmicznej. Od ostatnich wydarzen minal ponad rok. Jarlboy pozwala sobie na coraz wiecej. Ostatnio podobno ktos na niego krzywo spojrzal spod kapelusza, wprowadzil wiec nowy przepis: zabrania sie noszenia kapeluszy w zamknietych pomieszczeniach. Usprawiedliwial swoja decyzje kultura osobista. Oczywiscie nieprzestrzeganie nowego przepisu prowadzilo do scigania listem gonczym lub prac spolecznych. Co o tym sadzisz, co robisz?
            </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/kultura" className="button--link--parag">
                        Nie nosze kapeluszy, mnie to nie dotyczy. To tylko jakis smieszny przepis, dzieki ktoremu czesc ludzi nauczy sie kulury osobistej. To wrecz przywilej dla nich!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/ekspertyza" className="button--link--parag">
                        Nie nosze kapeluszy, ale jestem oburzony! To przemoc i dyskryminacja! Musze znalezc eksperta, ktory potwierdzi moje slowa i wspolnie zaapelujemy do zalogi. Niech ludzie sie w koncu przebudza! Viva la resistance!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}