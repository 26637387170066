import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Kultura() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestescie grupa szamanow lecaca na planete Tera Con Prime. Niepokoja Was nastroje na statku powodowane przez Jarlboya. Widzicie, ze wielu ludzi sie boi, niektorzy - niewygodni nagle znikaja, a coraz to nowe grupy spoleczne maja ograniczane prawa. Pewnego dnia gniew Jarlboya dosiega rowniez Was - szamanow. Z dnia na dzien zakazane zostaja wszelkie rytualy, szamanstwa, astrolokacje i takie sytuacje. Co Wy na to?
            </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/salon" className="button--link--parag">
                        Szaman zawsze jest wolny, bez wzgledu na panujace prawo - jak nam zabronia tworzyc w oficjalnym obiegu, bedziemy to robic w podziemiu.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/mendex" className="button--link--parag">
                        Nie damy sobie odebrac wolnosci - bedziemy odprawiac rytualy przed sala tronowa Jarlboya, az nam ustapi lub dosiegnie go ktorys z naszych rytualow!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}