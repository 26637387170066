import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Monitoring() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes osoba odpowiedzialna za wykrywanie niezidentyfikowanych obiektow latajacych pojawiajacych sie w sferze Waszej misji. Jak dotad spotykaliscie glownie zagubione sputniki, a najwiekszym zagrozeniem byly komety i gorace okruchy gwiezdne. Czesto w pracy zdarza Ci sie zdrzemnac, ostatnio jednak odczuwasz jakies dziwne pobudzenie. Sekunde temu wszystkie lampy w Twoim biurze pozapalaly sie na czerwono i zaczely wyc syreny. W szybkim tempie zbliza sie do Was latajacy obiekt. Wyglada troche jak smok, troche jak mechaniczny delfin i wysyla do Ciebie nieprzetlumaczalne komunikaty. Co robisz? </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/atak" className="button--link--parag">
                        Do ataku! Ten kosmos jest za maly dla nas dwoch! Informuje Jarlboya i laduje dziala jadrowe.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/tlumacz" className="button--link--parag">
                        Wzywam tlumacza kosmiczno-ziemskiego, aby zrozumiec, o co chodzi zalodze mechanicznego delfina.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}