import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Przewodniczaca() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes przewodniczaca Rady Statku, osoba wspolpracujaca najczesciej z Jarlboyem. Po odejsciu Jarlboya, to Ty przejmujesz dowodzenie na knarze oraz wszystkie zwiazane z tym obowiazki. Balagan po latach zarzadzania strachem i przemoca, jest okropny, ale nie wszystko jeszcze stracone. Nadal macie szanse dotrzec na planete Tera Con Prime i tam ukonczyc misje, docierajac do kawezedow i budujac z nimi nowa, wspaniala cywilizacje. Wazne, aby juz teraz ustalic swoje priorytety. Jak sie czujesz w nowej roli? Co planujesz?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/mendex" className="button--link--parag">
                        Przede wszystkim opanuje biezaca sytuacje, zwroce wolnosc i przywroce dzialanie prawa, od tego zaczne. Moj cel to pokoj. Tera Con Prime. KAWEZEDZI!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/pycha" className="button--link--parag">
                        Co mysle? Od poczatku wiedzialam, ze ten czlowiek nie nadaje sie na Jarlboya tak waznej misji. Teraz ja tu rzadze! Wszyscy zwolennicy Jarlboya moga sie juz trzasc ze strachu i czekac na kare!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}