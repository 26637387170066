import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from 'react';

export default function Dylemat(){

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Co tu duzo mowic jest problem i jakos musisz go rozwiazac. W budzecie brak funduszow na naprawe tej klimatyzacji wiec trzeba pieniadze znalezc gdzie indziej. Jak pokryjesz te koszta?</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/monitoring" className="button--link--parag">
                        Na co komu fundusz kulturalny, przeciez kazdy ma dostep w swojej
                        kabinie do hologramu, a publiczne koncerty czy e-wystawy to jakas smieszna fanaberia.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/mendex" className="button--link--parag">
                        Fundusz obronny przez wrogimi jednostkami pozaziemskimi lezy nietkniety
                        od poczatku misji, moze to czas by troche go wykorzystac?
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}