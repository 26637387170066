import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Porzadkowa() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Jestes dowodca Sluzb Szeryfowych. Przed wyprawa byles jednym z niewielu tajnych agentow nalezacych do Firmy Ochroniarskiej “Wiking”. Ty i Twoi wojowie dostajecie rozkaz stlumienia rebelii. Co czujesz, co myslisz i co zdecydujesz w tej sytuacji?
            </p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/kryzys" className="button--link--parag">
                        W koncu cos sie bedzie dzialo! Czas Pokazac im skad wzial sie moj przydomek z dawnych lat!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/rada" className="button--link--parag">
                        Nie zrobie tego, odmawiam. Zalozeniem misji bylo przeprowadzic ja w sposob pokojowy, a tym bardziej nie agresywny w stosunku do wlasnej zalogi. Przekaze sprawe Radzie statku zeby przyjrzala sie blizej poczynaniom Jarlboya i Dowodcy Sil Szeryfowych.
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}