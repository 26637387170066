import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function Upadek() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <div className="paragrafowa--content">
            <p>Na tym konczy sie misja Tera Con Prime. Nie odnajdziesz kawezedow. Jako Jarlboy zarzadzales przez strach i przemoc. Zalozeniem misji bylo, ze dolecicie tam wszyscy razem, dbajac wzajemnie o to, by kazdy czul sie swobodnie, godnie i nie krzywdzil innych. To sie jednak nie udalo. Zabraklo reagowania na dyskryminacje i wykluczenie slabszych. Zabraklo odwagi i energii, by przeciwstawic sie jednej przemocowej osobie. Nie okazaliscie sie godni poznania tajnikow kawezedow.</p>
            <div className="cont">
                <button className="timer--button">
                    <Link to="/wyprawa" className="button--link--parag">
                        Niestety misja zakonczyla sie niepowidzeniem, sprobuj jescze raz!
                    </Link>
                    <span className="first"></span>
                    <span className="second"></span>
                    <span className="third"></span>
                    <span className="fourth"></span>
                </button>
            </div>
        </div>
    )
}