import React, {useEffect, useState} from 'react';
import axios from 'axios';

function WordleAnswers() {
    const [todayValue, setTodayValue] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const options = {
                method: 'GET',
                url: 'https://wordle-answers-solutions.p.rapidapi.com/today',
                headers: {
                    'X-RapidAPI-Key': '4608dc4e78msh591f590f27b7de2p1076f7jsn2ff8483a357c',
                    'X-RapidAPI-Host': 'wordle-answers-solutions.p.rapidapi.com'
                }
            };

            try {
                const response = await axios.request(options);
                const wordleData = response.data;
                setTodayValue(wordleData.today);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return todayValue;
}

export default WordleAnswers;