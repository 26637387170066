import React, {useState} from "react";
import ceasar from "../../../img/cs.png";



export default function Rome() {

    // React States
    const [isLong, setisLong] = useState({});

    // User Login info
    const database = [
        {
            password: "euxwxv"
        }
    ];
    const errors = {
        pass: "Qlhvwhwb coh kdvor"
    };

    const renderErrorMessage = (name) =>
        name === isLong.name && (
            <div className="error-csr">{isLong.message}</div>
        );

    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
        var { pass } = document.forms[0];
        // Compare user info

        if (pass.value !== database[0].password) {
            // Invalid password
            console.log("ZLEEEEEE, przypominam o malych literach i o szyfrze");
            setisLong({name: "pass", message: errors.pass});
        } else {
            console.log("GIT");
            window.location.href = "/rebus";
        }

    };

    //TEKST W DIV ->
    //Nareszcie przyszedl czas dowiedziec sie o kawezedach troche wiecej.
    // Jak juz zdazyliscie zauwazyc jestesmy bardzo tajemniczy i uwielbiami zagdaki.
    // Zaraz po tej stronie obiecujemy ze czeka Was ostatnie wyszwanie zebyscie w koncu mogli dostac sie na nasza planete.
    // Nasza nazwa to tak naprawde polaczenie kilku slow. Kawezedzi znacza nie mniej nie wiecej niz KOSMICZNI WIKINGOWIE Z DZIKIEGO ZACHODU!
    // Niestety to nie czas ani miejsce zebyscie dowiedzieli sie jeszcze skad sie tak naprawde wzielismy i co mamy wspolnego z Hakonitami.
    // Dlatego zebyscie w koncu mogli nas poznac osobiscie i zebysmy mogli Wam powiedziec o co w tym wszystkim tak naprawde chodzi
    // wystarczy ze wpiszecie imie osoby ktora wbila mi noz w plecy ;)

    return(

        //TODO zdjecie cezara z liczba 3 (bo przesuniecie jest o tyle)
        //TODO dodac przycisk z haslem i przenisieniem dalej
        <div className="paragrafowa--content">
            <div className="caesar-content">
            <div>Qduhvcflh sucbvchgo fcdv grzlhgclhf vlh r ndzhchgdfk wurfkh zlhfhm.
            Mdn mxc cgdcbolvflh cdxzdcbf mhvwhvpb edugcr wdmhpqlfcb l xzlhoeldpl cdjgdnl.
            Cdudc sr whm vwurqlh relhfxmhpb ch fchnd Zdv rvwdwqlh zbczdqlh chebvflh z nrqfx prjol grvwdf vlh qd qdvcd sodqhwh.
            Qdvcd qdczd wr wdn qdsudzgh srodfchqlh nlonx vorz. Ndzhchgcl cqdfcd qlh pqlhm qlh zlhfhm qlc NRVPLFCQL ZLNLQJRZLH C GCLNLHJR CDFKRGX!
            Qlhvwhwb wr qlh fcdv dql plhmvfh chebvflh grzlhgclhol vlh mhvcfch vndg vlh wdn qdsudzgh zclholvpb l fr pdpb zvsroqhjr c Kdnrqlwdpl.
            Godwhjr chebvflh z nrqfx prjol qdv srcqdf rvrelvflh l chebvpb prjol Zdp srzlhgclhf r fr z wbp zvcbvwnlp wdn qdsudzgh fkrgcl
                zbvwdufcb ch zslvchflh lplh rvreb nwrud zelod pl qrc z sohfb :)</div>
            </div>
            <div className="caesar-container">
            <div className="caesar-content-help">
                <div className="form">
                    <form onSubmit={handleSubmit}>
                    <input type="text" className="caesar-input" name="pass" placeholder="Zsurzdgc rgs"></input>
                        {renderErrorMessage("pass")}
                    <button onClick={handleSubmit} className="caesar-button">Kdlo Fdhvdu</button>
                    </form>
                </div>
            </div>
            </div>
            <img id="img" src={ceasar}  className="caesar-image" alt="ceasar"/>

        </div>

    )

}